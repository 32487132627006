import { tenants, dimensions, categories, positionsTypes, meters } from '../constants'

const rulesTypes = {
  LESS_THAN: 'LESS_THAN',
  GREATER_THAN: 'GREATER_THAN',
  BETWEEN: 'BETWEEN'
}

const allRules = new Map([
  [tenants.TRICEPS, {
    rule: null,
    child: new Map([
      [dimensions.NUTRITION, {
        rule: null,
        child: new Map([
          [categories.MALE_ADULT, {
            rule: null,
            child: new Map([
              [positionsTypes.STRIKER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.6,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 23
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 23
                        },
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 50,
                          max: 51.9
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 49
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 35
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 35,
                          max: 45
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 45
                        },
                      ],
                    }
                  }]
                ])
              }],
              [positionsTypes.GOAL_KEEPER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.6,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 23
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 23
                        },
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 50,
                          max: 51.9
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 49
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 45
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 45.1,
                          max: 50
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 50.1
                        },
                      ]
                    }
                  }]
                ])
              }]
            ])
          }],
          // TODO: ACÁ VAN LAS REGLAS DE SUB_17
          [categories.SUB_17, {
            rule: null,
            child: new Map([
              [positionsTypes.STRIKER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.7,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 26
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 26
                        }
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 54.4,
                          max: 44
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 44
                        }
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 40
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 40,
                          max: 50
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 50.1
                        },
                      ],
                    }
                  }]
                ])
              }],
              [positionsTypes.GOAL_KEEPER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.7,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 26
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 26
                        }
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 54.4,
                          max: 44
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 44
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 50
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 49.9,
                          max: 60
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 60.1
                        },
                      ]
                    }
                  }]
                ])
              }]
            ])
          }],


          // TODO ACÁ VA FEMENINO ADULTO Y PROYECCIÓN queda con 5 clasificaciones
          [categories.FEMALE_ADULT, {
            rule: null,
            child: new Map([
              [positionsTypes.STRIKER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 21
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 21.1,
                          max: 24
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 24.1,
                          max: 29
                        },
                        {
                          clasification: 'Elevado',
                          color: '#FFA500', // Cambié a naranja
                          type: rulesTypes.BETWEEN,
                          min: 29.1,
                          max: 34
                        },
                        {
                          clasification: 'Muy Elevado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 34
                        }
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 47.5
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 43.9,
                          max: 47.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 36.4,
                          max: 43.8
                        },
                        {
                          clasification: 'Bajo',
                          color: '#FFA500', // Cambié a naranja
                          type: rulesTypes.BETWEEN,
                          min: 32.7,
                          max: 36.3
                        },
                        {
                          clasification: 'Muy Bajo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 32.7
                        }
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 60
                        },
                      ],
                    }
                  }]
                ])
              }],
              [positionsTypes.GOAL_KEEPER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 21
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 21.1,
                          max: 24
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 24.1,
                          max: 29
                        },
                        {
                          clasification: 'Malo',
                          color: '#FFA500', // Cambié a naranja
                          type: rulesTypes.BETWEEN,
                          min: 29.1,
                          max: 34
                        },
                        {
                          clasification: 'Muy Elevado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 34
                        }
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 47.5
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 43.9,
                          max: 47.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 36.4,
                          max: 43.8
                        },
                        {
                          clasification: 'Bajo',
                          color: '#FFA500', // Cambié a naranja
                          type: rulesTypes.BETWEEN,
                          min: 32.7,
                          max: 36.3
                        },
                        {
                          clasification: 'Muy Bajo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 32.7
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 60
                        },
                      ]
                    }
                  }]
                ])
              }]
            ])
          }],
        ])
      }]
    ])
  }],
  [tenants.UCATOLICA, {
    rule: null,
    child: new Map([
      [dimensions.NUTRITION, {
        rule: null,
        child: new Map([
          [categories.MALE_ADULT, {
            rule: null,
            child: new Map([
              [positionsTypes.STRIKER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 18.5
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 18.6,
                          max: 20
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 20.1,
                          max: 23
                        },
                        {
                          clasification: 'Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 23
                        },
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 52
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 50,
                          max: 51.9
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 48,
                          max: 49.9
                        },
                        {
                          clasification: 'Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 48
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 42
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 42.6,
                          max: 45
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 45.6,
                          max: 50
                        },
                        {
                          clasification: 'Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 50
                        },
                      ],
                    }
                  }]
                ])
              }],
              [positionsTypes.GOAL_KEEPER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 18.5
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.6,
                          max: 20
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 20.1,
                          max: 23
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 23
                        },
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 52
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 50,
                          max: 51.9
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 48,
                          max: 49.9
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 48
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 45
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 41.5,
                          max: 50
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 50.1
                        },
                      ]
                    }
                  }]
                ])
              }]
            ])
          }]
        ])
      }]
    ])
  }],
  [tenants.PALESTINO, {
    rule: null,
    child: new Map([
      [dimensions.NUTRITION, {
        rule: null,
        child: new Map([
          [categories.MALE_ADULT, {
            rule: null,
            child: new Map([
              [positionsTypes.STRIKER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.6,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 23
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 23
                        },
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 50,
                          max: 51.9
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 49
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 35
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 35,
                          max: 45
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 45
                        },
                      ],
                    }
                  }]
                ])
              }],
              [positionsTypes.GOAL_KEEPER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.6,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 23
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 23
                        },
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 50,
                          max: 51.9
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 49
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 45
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 45.1,
                          max: 50
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 50.1
                        },
                      ]
                    }
                  }]
                ])
              }]
            ])
          }],
          // TODO: ACÁ VAN LAS REGLAS DE SUB_17
          [categories.SUB_17, {
            rule: null,
            child: new Map([
              [positionsTypes.STRIKER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.7,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 26
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 26
                        }
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 54.4,
                          max: 44
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 44
                        }
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 40
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 40,
                          max: 50
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 50.1
                        },
                      ],
                    }
                  }]
                ])
              }],
              [positionsTypes.GOAL_KEEPER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.7,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 26
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 26
                        }
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 54.4,
                          max: 44
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 44
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 50
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 49.9,
                          max: 60
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 60.1
                        },
                      ]
                    }
                  }]
                ])
              }]
            ])
          }],


          // TODO ACÁ VA FEMENINO ADULTO Y PROYECCIÓN queda con 5 clasificaciones
          [categories.FEMALE_ADULT, {
            rule: null,
            child: new Map([
              [positionsTypes.STRIKER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 21
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 21.1,
                          max: 24
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 24.1,
                          max: 29
                        },
                        {
                          clasification: 'Elevado',
                          color: '#FFA500', // Cambié a naranja
                          type: rulesTypes.BETWEEN,
                          min: 29.1,
                          max: 34
                        },
                        {
                          clasification: 'Muy Elevado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 34
                        }
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 47.5
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 43.9,
                          max: 47.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 36.4,
                          max: 43.8
                        },
                        {
                          clasification: 'Bajo',
                          color: '#FFA500', // Cambié a naranja
                          type: rulesTypes.BETWEEN,
                          min: 32.7,
                          max: 36.3
                        },
                        {
                          clasification: 'Muy Bajo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 32.7
                        }
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 60
                        },
                      ],
                    }
                  }]
                ])
              }],
              [positionsTypes.GOAL_KEEPER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 21
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 21.1,
                          max: 24
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 24.1,
                          max: 29
                        },
                        {
                          clasification: 'Malo',
                          color: '#FFA500', // Cambié a naranja
                          type: rulesTypes.BETWEEN,
                          min: 29.1,
                          max: 34
                        },
                        {
                          clasification: 'Muy Elevado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 34
                        }
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 47.5
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 43.9,
                          max: 47.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 36.4,
                          max: 43.8
                        },
                        {
                          clasification: 'Bajo',
                          color: '#FFA500', // Cambié a naranja
                          type: rulesTypes.BETWEEN,
                          min: 32.7,
                          max: 36.3
                        },
                        {
                          clasification: 'Muy Bajo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 32.7
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 60
                        },
                      ]
                    }
                  }]
                ])
              }]
            ])
          }],
        ])
      }]
    ])
  }],
  [tenants.ANFP, {
    rule: null,
    child: new Map([
      [dimensions.NUTRITION, {
        rule: null,
        child: new Map([
          [categories.MALE_ADULT, {
            rule: null,
            child: new Map([
              [positionsTypes.STRIKER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.6,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 23
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 23
                        },
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 50,
                          max: 51.9
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 49
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 35
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 35,
                          max: 45
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 45
                        },
                      ],
                    }
                  }]
                ])
              }],
              [positionsTypes.GOAL_KEEPER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.6,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 23
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 23
                        },
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 50,
                          max: 51.9
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 49
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 45
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 45.1,
                          max: 50
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 50.1
                        },
                      ]
                    }
                  }]
                ])
              }]
            ])
          }],
          // TODO: ACÁ VAN LAS REGLAS DE SUB_17
          [categories.SUB_17, {
            rule: null,
            child: new Map([
              [positionsTypes.STRIKER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.7,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 26
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 26
                        }
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 54.4,
                          max: 44
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 44
                        }
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 40
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 40,
                          max: 50
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 50.1
                        },
                      ],
                    }
                  }]
                ])
              }],
              [positionsTypes.GOAL_KEEPER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.7,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 26
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 26
                        }
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 54.4,
                          max: 44
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 44
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 50
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 49.9,
                          max: 60
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 60.1
                        },
                      ]
                    }
                  }]
                ])
              }]
            ])
          }]
        ])
      }]
    ])
  }],
  [tenants.COQUIMBO, {
    rule: null,
    child: new Map([
      [dimensions.NUTRITION, {
        rule: null,
        child: new Map([
          [categories.MALE_ADULT, {
            rule: null,
            child: new Map([
              [positionsTypes.STRIKER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 18
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 18,
                          max: 20
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 20.1,
                          max: 22.9
                        },
                        {
                          clasification: 'Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 23
                        },
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 52
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 50.7,
                          max: 51.9
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 44,
                          max: 50.7
                        },
                        {
                          clasification: 'Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 44
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 35
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 35,
                          max: 50
                        },
                        {
                          clasification: 'Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 60
                        },
                      ],
                    }
                  }]
                ])
              }],
              [positionsTypes.GOAL_KEEPER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.6,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 23
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 23
                        },
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 50,
                          max: 51.9
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 49
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 45
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 45.1,
                          max: 50
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 50.1
                        },
                      ]
                    }
                  }]
                ])
              }],
            ])
          }]
        ])
      }]
    ])
  }],
  [tenants.COLOCOLO, {
    rule: null,
    child: new Map([
      [dimensions.NUTRITION, {
        rule: null,
        child: new Map([
          [categories.MALE_ADULT, {
            rule: null,
            child: new Map([
              [positionsTypes.STRIKER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente/Bueno',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 20
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 20.1,
                          max: 23
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 23
                        },
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 52
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 51.9,
                          max: 44
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 43.9
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 40
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 40.1,
                          max: 49.9
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 50
                        },
                      ],
                    }
                  }]
                ])
              }],
              [positionsTypes.GOAL_KEEPER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente/Bueno',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 20
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 20.1,
                          max: 23
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 23
                        },
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 52
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 51.9,
                          max: 44
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 43.9
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 45
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 45.1,
                          max: 50
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 50.1
                        },
                      ]
                    }
                  }]
                ])
              }]
            ])
          }],
          // TODO: ACÁ VAN LAS REGLAS DE SUB_17
          [categories.SUB_17, {
            rule: null,
            child: new Map([
              [positionsTypes.STRIKER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.7,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 26
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 26
                        }
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 54.4,
                          max: 44
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 44
                        }
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 40
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 40,
                          max: 50
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 50.1
                        },
                      ],
                    }
                  }]
                ])
              }],
              [positionsTypes.GOAL_KEEPER, {
                rule: null,
                child: new Map([
                  ['metersRules', {
                    rule: {
                      [meters.ADIPOSE_TISSUE]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 16.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 16.7,
                          max: 18
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 18.1,
                          max: 26
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 26
                        }
                      ],
                      [meters.MUSCLE_MASS]: [
                        {
                          clasification: 'Excelente',
                          color: '#548135',
                          type: rulesTypes.GREATER_THAN,
                          min: 54.6
                        },
                        {
                          clasification: 'Bueno',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 52,
                          max: 54.5
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#FFFF00',
                          type: rulesTypes.BETWEEN,
                          min: 54.4,
                          max: 44
                        },
                        {
                          clasification: 'Muy Malo',
                          color: '#FF0000',
                          type: rulesTypes.LESS_THAN,
                          min: 44
                        },
                      ],
                      [meters.SUM_SIX_FOLDS]: [
                        {
                          clasification: 'Óptimo',
                          color: '#548135',
                          type: rulesTypes.LESS_THAN,
                          max: 50
                        },
                        {
                          clasification: 'Aceptable',
                          color: '#A8D08C',
                          type: rulesTypes.BETWEEN,
                          min: 49.9,
                          max: 60
                        },
                        {
                          clasification: 'Sobre lo esperado',
                          color: '#FF0000',
                          type: rulesTypes.GREATER_THAN,
                          min: 60.1
                        },
                      ]
                    }
                  }]
                ])
              }]
            ])
          }]
        ])
      }]
    ])
  }],
  // OTHER DIMENSIONS RULES ...
])

export { rulesTypes, allRules }
